import { extendTheme, baseTheme } from '@chakra-ui/react'

const theme = {
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Montserrat, sans-serif',
    body: 'Montserrat, sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '0px', // Set borderRadius to 0px for no rounded corners
      },
    },
  },
  colors: {
    black: '#1A1A1A',
    white: '#f2f2f2',
    brand: {
      50: '#fffca1',
      100: '#ffe289',
      200: '#ffc870',
      300: '#ffaf59',
      400: '#ff9741',
      500: '#e97f29',
      600: '#cd680c',
      700: '#b15100',
      800: '#953b00',
      900: '#7b2400',
    },
  },
  styles: {
    global: props => ({
      html: {
        backgroundColor: 'black',
      },
    }),
  },
}

export default extendTheme(theme)
export { baseTheme }
